"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.base = void 0;
exports.base = {
    addColumnAfter: "Insert column after",
    addColumnBefore: "Insert column before",
    addRowAfter: "Insert row after",
    addRowBefore: "Insert row before",
    alignCenter: "Align center",
    alignLeft: "Align left",
    alignRight: "Align right",
    bulletList: "Bulleted list",
    checkboxList: "Todo list",
    codeBlock: "Code block",
    codeCopied: "Copied to clipboard",
    codeInline: "Code",
    createLink: "Create link",
    createLinkError: "Sorry, an error occurred creating the link",
    createNewDoc: "Create a new doc",
    deleteColumn: "Delete column",
    deleteRow: "Delete row",
    deleteTable: "Delete table",
    deleteImage: "Delete image",
    downloadImage: "Download image",
    replaceImage: "Replace image",
    alignImageLeft: "Float left half width",
    alignImageRight: "Float right half width",
    alignImageDefault: "Center large",
    em: "Italic",
    embedInvalidLink: "Sorry, that link won’t work for this embed type",
    findOrCreateDoc: "Find or create a doc…",
    h1: "Big heading",
    h2: "Medium heading",
    h3: "Small heading",
    heading: "Heading",
    hr: "Divider",
    image: "Image",
    imageUploadError: "Sorry, an error occurred uploading the image",
    imageCaptionPlaceholder: "Write a caption",
    info: "Info",
    infoNotice: "Info notice",
    link: "Link",
    linkCopied: "Link copied to clipboard",
    mark: "Highlight",
    newLineEmpty: "Type '/' to insert…",
    newLineWithSlash: "Keep typing to filter…",
    noResults: "No results",
    openLink: "Open link",
    orderedList: "Ordered list",
    pageBreak: "Page break",
    pasteLink: "Paste a link…",
    pasteLinkWithTitle: (title) => `Paste a ${title} link…`,
    placeholder: "Placeholder",
    quote: "Quote",
    removeLink: "Remove link",
    searchOrPasteLink: "Search or paste a link…",
    strikethrough: "Strikethrough",
    strong: "Bold",
    subheading: "Subheading",
    table: "Table",
    tip: "Tip",
    tipNotice: "Tip notice",
    warning: "Warning",
    warningNotice: "Warning notice",
};
exports.default = exports.base;
